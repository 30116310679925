import { Injectable } from '@angular/core';
import { SiteConfig } from '../../models/authentication/site-config.model';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  configName: string;
  constructor() { this.configName = 'SiteConfig'; }

  saveAccessToken(accessToken: string): void {
    let date: Date = new Date();
    date.setMinutes(date.getMinutes() + 58);
    let siteConfig: SiteConfig = {
      accessToken: accessToken,
      expiry: date
    }
    localStorage.setItem(this.configName, JSON.stringify(siteConfig));
  }

  static toUrl(input: string): string {
    input = input.replace(/[^a-zA-Z ]/g, '-').toLowerCase();
    input = input.replace(/ /g, '-');
    input = input.replace(/--/g, '-');
    input = input.replace(/---/g, '-');
    input = input.replace(/----/g, '-');
    input = input.replace(/-----/g, '-');

    return input;
  }

  getAccessToken(): string {

    var key = localStorage.getItem(this.configName);
    if (key != '' && key != undefined && key != null) {
      try {
        let siteConfig: SiteConfig = JSON.parse(key);
        let now: Date = new Date();
        let timeDiff = ((new Date(siteConfig.expiry).getTime() - now.getTime()) / 1000) / 60;
        if (timeDiff > 0) {
          return siteConfig.accessToken;
        } else { return '' }
        console.log(timeDiff);
        return siteConfig.accessToken;
      } catch  { return '' }
    } else { return ''; }


  }
}
