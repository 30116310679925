import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class NavigateService {

  constructor(private _router: Router) { }

  goToLogin(): void {
    this._router.navigate(['/login']);
  }

  goToDashboard(): void {
    this._router.navigate(['/dashboard']);
  }

  goToCompanyUserRoles(id: number, name: string): void {
    name = UtilityService.toUrl(name);
    this._router.navigate(['/company/user/user-roles/' + id + '/' + name]);
  }

  gotToCompanyUsers(): void {
    this._router.navigate(['/company/user/all']);
  }

  goToPricingRules(serviceName: string, serviceId: number, pricingModuleName: string, modelId: number): void {
    serviceName = UtilityService.toUrl(serviceName);
    pricingModuleName = UtilityService.toUrl(pricingModuleName);
    this._router.navigate(['/company/service/rules/' + serviceName + '/' + serviceId + '/' + pricingModuleName + '/' + modelId + '/all']);
  }

  goToModulePricingModels(): void {
    this._router.navigate(['/company/service-model']);
  }

  goToCarRentalCreate(moduleId: number, moduleName: string, pricingModelName: string, modelId: number): void {
    moduleName = UtilityService.toUrl(moduleName);
    pricingModelName = UtilityService.toUrl(pricingModelName);
    this._router.navigate(['/company/service/rules/' + moduleName + '/' + moduleId + '/' + pricingModelName + '/' + modelId + '/create']);
  }

  goToCarRentalEdit(moduleId: number, moduleName: string, pricingModelName: string, modelId: number
    , ruleName: string, ruleId: number): void {
    moduleName = UtilityService.toUrl(moduleName);
    pricingModelName = UtilityService.toUrl(pricingModelName);
    ruleName = UtilityService.toUrl(ruleName);
    this._router.navigate(['/company/service/rules/' + moduleName + '/' + moduleId + '/' + pricingModelName
      + '/' + modelId + '/' + ruleName + '/' + ruleId + '/edit']);
  }

  goToSuppliers(): void {
    this._router.navigate(['/supplier/all']);
  }

  goToSupplierUsers(supplierId: number, supplierName: string): void {
    supplierName = UtilityService.toUrl(supplierName);
    this._router.navigate(['/supplier/users/' + supplierId + '/' + supplierName]);
  }

  goToSupplierUserRoles(supplierId: number, supplierName: string, userId: number, userName: string): void {
    supplierName = UtilityService.toUrl(supplierName);
    userName = UtilityService.toUrl(userName);

    this._router.navigate(['/supplier/users/roles/' + supplierId + '/' + supplierName + '/' + userId
      + '/' + userName])
  }

}
