import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../utility/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private notificationService: NotificationService) { }

  errorHandler(httpErrorResponse: HttpErrorResponse): void {

    switch (httpErrorResponse.status) {
      case 500:       
        this.notificationService.errorNotification('Error', 'Could not connect with server. Please try later.');
        break;

      default:
        break;
    }

  }

}
