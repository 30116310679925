import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './services/authentication/authentication.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  interval = null;

  constructor(private router: Router
    , private authenticationService: AuthenticationService
    , private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.checkAuthentication();

  }

  checkAuthentication() {
    const accessToken = this.authenticationService.getAccessToken();
    if (accessToken) {
      this.authenticationService.refreshToken().subscribe(response => {
        this.authenticationService.refreshData = response;
        this.authenticationService.saveAppsettings(response);
        this.authenticationService.saveAccessToken(response.accessToken);
        this.startInterval();
      });
    }
  }

  refreshToken() {
    const accessToken = this.authenticationService.goingToExpire();
    if (accessToken) {
      clearInterval(this.interval);
      this.checkAuthentication();
    }
  }

  startInterval() {
    const self = this;
    this.interval = setInterval(function () {
      self.refreshToken();
    }, 3000);
  }

}
