import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastrService: ToastrService) { }

  successNotification(title: string, message: string) {
    this.toastrService.success(message, title);
  }

  errorNotification(title: string, message: string) {
    this.toastrService.error(message, title);
  }

  warningNotification(title: string, message: string) {
    this.toastrService.warning(message, title);
  }

}
