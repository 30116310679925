import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { NotificationService } from '../../services/utility/notification.service';
import { ErrorService } from '../../services/utility/error.service';
import { NavigateService } from '../../services/utility/navigate.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  isSubmitted: boolean = false;
  get f() { return this.forgotPasswordForm.controls };
  isActionProcessing: boolean;

  constructor(private _formBuilder: FormBuilder
    , private authenticationService: AuthenticationService
    , private notificationService: NotificationService
    , private errorService: ErrorService
    , private navigateService: NavigateService) {
    this.isActionProcessing = false;
  }

  ngOnInit(): void {
    this.initForgotPasswordForm();
  }

  initForgotPasswordForm(): void {
    this.forgotPasswordForm = this._formBuilder.group({
      agencyCode: ['', Validators.required],
      emailAddress: ['', Validators.required],

    });
  }

  onForgotPassword(): void {
    this.isSubmitted = true;
    if (this.forgotPasswordForm.invalid) { return; }
    this.isActionProcessing = true;
    let agencyCode = this.forgotPasswordForm.controls.agencyCode.value;
    let email = this.forgotPasswordForm.controls.emailAddress.value;

    this.authenticationService.forgotPassword(agencyCode, email).subscribe(isSent => {
      this.isActionProcessing = false;
      if (isSent) {
        this.notificationService.successNotification('Success', 'Your password sent to ' + email + ' successfully');
        this.navigateService.goToLogin();
      } else {
        this.notificationService.errorNotification('Not Found', email + ' does not registered.');
      }
    }, error => {
      this.isActionProcessing = false;
      this.errorService.errorHandler(error);
    });

  }

}
