import { Injectable } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidator extends Validators {

  static Year(control: FormControl) {
    // first check if the control has a value
    if (control.value && control.value.length > 0) {
      // match the control value against the regular expression
      let isNumber = isNaN(Number(control.value)) ? false : true;
      if (isNumber) {
        isNumber = (Number(control.value) % 1 === 0 ? true : false);
      }
      const input = control.value;
      if (input.length > 0) { } else { control.setErrors(null); }
      if (isNumber) {
        if (input.length == 4) {
          return null;
        } else { return { year: true }; }
      } else {
        return { year: true };
      }
    }
    else {
      return null;
    }
  }

  static MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  static IntergerOnly(control: FormControl) {
    // first check if the control has a value
    if (control.value && control.value.length > 0) {
      // match the control value against the regular expression
      let isNumber = isNaN(Number(control.value)) ? false : true;
      if (isNumber) {
        isNumber = (Number(control.value) % 1 === 0 ? true : false);
      }
      const input = control.value;
      if (input.length > 0) { } else { control.setErrors(null); }
      return !isNumber ? { number: true } : null;
    }
    else if (control.value === 0) {
      return { number: true }
    }
    else {
      return null;
    }
  }

  static IntergerOnlyIncludeZero(control: FormControl) {
    // first check if the control has a value
    if (control.value && control.value.length > 0) {
      // match the control value against the regular expression
      let isNumber = isNaN(Number(control.value)) ? false : true;
      if (isNumber) {
        isNumber = (Number(control.value) % 1 === 0 ? true : false);
      }
      const input = control.value;
      if (input.length > 0) { } else { control.setErrors(null); }
      return !isNumber ? { number: true } : null;
    }
    else {
      return null;
    }
  }

  static NumberOnlyIncludeZero(control: FormControl) {
    // first check if the control has a value
    if (control.value && control.value.length > 0) {
      // match the control value against the regular expression
      let isNumber = isNaN(Number(control.value)) ? false : true;

      const input = control.value;
      if (input.length > 0) { } else { control.setErrors(null); }
      return !isNumber ? { number: true } : null;
    }
    else {
      return null;
    }
  }


  passwordMatchValidator(newPassword: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[newPassword];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (
        confirmPasswordControl.errors &&
        !confirmPasswordControl.errors.passwordMismatch
      ) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    };
  }
}
