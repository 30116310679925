
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { NavigateService } from '../../services/utility/navigate.service';
import { navItems,acaciaAdmin,acaciaUser, carTransferUser, selfdriveSighttour, selfdriveReservationsSighttour, carDeliveryBookingUser } from '../../_nav';
import { carDeliveryUser } from './../../_nav';




@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit {
  myDate=Date.now();
  public sidebarMinimized = false;
  public navItems;
  refreshResponse: any;

  constructor(
    private _navigationService: NavigateService,
    private _authenticationService: AuthenticationService) { }

  ngOnInit(): void {

    this._authenticationService.refreshToken().subscribe(data =>{
      console.log(data);
      if (data.emailAddress === "test@gmail.com") {
        this.navItems = navItems;
      } else if (data.emailAddress === "ahmed@hekaya.co") {
       this.navItems = navItems;
      } else if (data.emailAddress === "Hisham.2018h@Icloud.com"){
        this.navItems = acaciaAdmin;
      } else if (data.emailAddress === "bin9sulaiman94@icould.com"){
        this.navItems = acaciaUser;
      } else if (data.emailAddress === "alzajiltrade@gmail.com") {
        this.navItems = carTransferUser;
      } else if (data.emailAddress === "mustafa@sightstours.com") {
        this.navItems = selfdriveSighttour;
      } else if (data.emailAddress === "reservations@sightstours.com") {
        this.navItems = selfdriveReservationsSighttour;
      } else if (data.emailAddress === "ali.bimani@zajildriver.com") {
        this.navItems = carDeliveryUser;
      } else if (data.emailAddress === "zajilbookings@gmail.com") {
        this.navItems = carDeliveryBookingUser;
      }

    });


  }

  public signOut() {
    this._authenticationService.logOut();

    let self = this;
    setTimeout(function () {
      self._navigationService.goToLogin();

    }, 1000);


  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
