import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SignIn } from '../../models/authentication/sign-in.model';
import { GenericService } from '../generic/generic.service';
import { map } from 'rxjs/operators';
import { Config } from '../../models/config/config.model';
import { SiteConfig } from '../../models/authentication/site-config.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  refreshData: any;
  configName: string;
  private appSettings: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private genericService: GenericService) {
    this.configName = 'SiteConfig';
  }

  public saveAppsettings(appSettings: any) {
    this.appSettings.next(appSettings);
  }

  public getAppSettings(): BehaviorSubject<any> {
    return this.appSettings;
  }

  signIn(emailAddress: string, password: string, agencyCode: string): Observable<SignIn> {
    let serviceUrl = Config.AuthSignIn + '?emailAddress=' + emailAddress + '&password=' + password + '&agencyCode=' + agencyCode;
    return this.genericService.getService(serviceUrl, false).pipe(map<any, SignIn>(res => res));
  }

  resetPassword(currentPassword:string, newPassword: string): Observable<any> {
    let serviceUrl = Config.resetPassword + '?currentPassword=' + currentPassword + '&newPassword=' + newPassword;
    return this.genericService.getService(serviceUrl, true);
  }


  forgotPassword(agencyCode: string, emailAddress: string): Observable<boolean> {
    let serviceUrl = Config.forgotPassword + '?EmailID=' + emailAddress + '&agencyCode=' + agencyCode;
    return this.genericService.getService(serviceUrl, false);
  }

  saveAccessToken(accessToken: string): void {
    let date: Date = new Date();
    date.setMinutes(date.getMinutes() + 58);
    let siteConfig = {
      accessToken: accessToken,
      expiry: date.toString()
    }
    localStorage.setItem(this.configName, JSON.stringify(siteConfig));
  }

  getAccessToken(): string {

    var key = localStorage.getItem(this.configName);
    if (key != '' && key != undefined && key != null) {
      try {
        let siteConfig = JSON.parse(key);
        let now: Date = new Date();
        let timeDiff = ((new Date(siteConfig.expiry).getTime() - now.getTime()) / 1000) / 60;
        if (timeDiff > 0) {
          return siteConfig.accessToken;
        } else { return '' }
        console.log(timeDiff);
        return siteConfig.accessToken;
      } catch { return '' }
    } else { return ''; }
  }

  goingToExpire(): string {
    let token = '';
    var key = localStorage.getItem(this.configName);
    if (key) {
      try {
        let siteConfig = JSON.parse(key);
        let now: Date = new Date();
        let timeDiff = ((new Date(siteConfig.expiry).getTime() - now.getTime()) / 1000) / 60;
        if (timeDiff < 2) {
          token = siteConfig.accessToken;
        }
      } catch (error) {

      }
    }
    return token;
  }

  refreshToken(): Observable<any> {
    let serviceUrl = Config.refresh;
    return this.genericService.getService(serviceUrl, true);
    
  }



  logOut() {
    localStorage.setItem(this.configName, '');
    this.appSettings.next(null);
  }


}
