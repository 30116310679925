import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-locationmaster',
  templateUrl: './locationmaster.component.html',
  styleUrls: ['./locationmaster.component.css']
})
export class LocationmasterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
