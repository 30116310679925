import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carmaster',
  templateUrl: './carmaster.component.html',
  styleUrls: ['./carmaster.component.css']
})
export class CarmasterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
