import { CustomValidator } from './../../services/utility/custom-validator.service';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidatorFn } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ErrorService } from '../../services/utility/error.service';
import { NotificationService } from '../../services/utility/notification.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePassword: FormGroup;
  isSubmitted: boolean = false;
  get f() { return this.changePassword.controls };
  constructor(private _formBuilder: FormBuilder
    , private customValidator:CustomValidator
    , private authenticationService: AuthenticationService
    , private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.initFormGroup();
  }
  

  //Form Group 
  initFormGroup(): void {
    this.changePassword = this._formBuilder.group({
      currentPassword: ['',[Validators.required] ],
      newPassword: ['',[Validators.required] ],
    },
    {
      validator:this.customValidator.passwordMatchValidator("newPassword","confirmPassword")
    }
    );
  }

  

  onChange(): void {
    this.isSubmitted = true;
    if (this.changePassword.invalid) {
      return;
    }
    this.authenticationService.resetPassword(this.changePassword.value.currentPassword,this.changePassword.value.newPassword).subscribe(updated =>{
      if(updated.wasSuccesfull){
        this.notificationService.successNotification("Success","Password reset successfully");
      } else {
        this.notificationService.errorNotification('',updated.error.message);
      }
    },error=>{
      console.log(error);
      this.notificationService.errorNotification('',error.error.message);
    })
  }


}
