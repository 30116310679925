
export class Config {

    //Access Modules
    static getAllAccessModules = '/api/access-list/get-all';
    static createAccessModules = '/api/access-list/create';
    static updateAccessModules = '/api/access-list/update';

    //Add On Master Service
    static addOnMasterGetAll = '/api/add-on-services/get-all-addon-master';
    static addOnMasterCreateAll = '/api/add-on-services/create_addon-master';
    static updateAddonMaster='/api/add-on-services/update-addon-master';
    static addOnMasterServicesGetAll = '/api/add-on-services/get?AddOnServiceId=';
    static addonServiceCreateAll = '/api/add-on-services/create';
    static updateAddonService = '/api/add-on-services/update-addon-service';
    static addonServiceGetAll = '/api/add-on-services/get-all-addon-service';
    static addonServiceByBookingModule = ''

    // Amount Types
    static amountTypeGetAll = '/api/amount-types/get-all';

    // Api Module Services
    static ApiModuleGetAll = '/api/supplier/get-all-api-modules';


    //Authentication services
    static AuthSignIn: string = '/api/auth/sign-in';
    static forgotPassword: string = '/api/auth/forgot-password';
    static refresh: string = '/api/auth/refresh';
    static resetPassword: string = '/api/auth/reset-password';



    // Booking module services
    static bookingModuleServiceGetAll: string = '/api/booking-module/get-all';

    //Bookings
    static bookingGetAll = '/api/bookings/get-all-bookings-for-agency-servicetype';
    static bookingSummary = '/api/bookings/get-booking-details-by-id';
    static BookingSearch = '';

    // Module Pricing model services
    static bookingModulePricingModelGetAll: string = '/api/booking-module-pricing-model/get-all';
    static bookingModulePricingServiceCreate: string = '/api/booking-module-pricing-model/create'
    static bookingModulePricingServiceUpdate: string = '/api/booking-module-pricing-model/update'
    static bookingModulePricingModelGet: string = '/api/booking-module-pricing-model/get';

    // Car rental pricing rules
    static carRentalPricingRuleGetAll = '/api/car-rental-pricing-rule/get-all';
    static carRentalPricingRuleCreate = '/api/car-rental-pricing-rule/create';
    static carRentalPricingRuleUpdate = '/api/car-rental-pricing-rule/update';
    static carRentalPricingRuleGet = '/api/car-rental-pricing-rule/get';
    static carRentalPricingRuleUpdatePriority = '/api/car-rental-pricing-rule/update-priority';

    // Country
    static countryGetAll = '/api/country/get-all';
    static cityGetAll = '/api/locations/get-all-from-country';
    static cityGetAllByLocationType = '/api/locations/get-all-by-location-type';

    // Currency
    static currencyGetAll = '/api/currency/get-all';

    //Delivery Threshold
    static deliveryThresholdGetAll = '/api/deliverythreshold/get-all-delivery-threshold';
    static deliveryThresholdInsert = '/api/deliverythreshold/insert-delivery-threshold';
    static deliveryThresholdUpdate = '/api/deliverythreshold/update-delivery-threshold';
    static deliveryThresholdSelfdriveGetAll = '/api/selfdrive-deliverythreshold/get-all-delivery-threshold';
    static deliveryThresholdSelfdriveInsert = '/api/selfdrive-deliverythreshold/insert-delivery-threshold';
    static deliveryThresholdSelfdriveById = '/api/selfdrive-deliverythreshold/get-delivery-threshold-by-id';
    static deliveryThresholdEquipmentsGetAll = '/api/equipments-deliverythreshold/get-all-delivery-threshold';
    static deliveryThresholdEquipmentsInsert = '/api/equipments-deliverythreshold/insert-delivery-threshold';
    static deliveryThresholdEquipmentsById = '/api/equipments-deliverythreshold/get-delivery-threshold-by-id';

    //Fare Type
    static fareTypeGetAll = '/api/add-on-services/get-all-fare-type';

    //Charge Type
    static chargeTypeGetAll = '/api/add-on-services/get-all-charge-type';

    // Location Services
    static locationByTypeGetAll = '/api/locations/get-all-by-location-type';
    static locationGetAll = '/api/locations/get-all';
    static locationgetAll = '/api/locations/get-all-with-details';
    static locationByBookingModule = '/api/locations/get-all-by-booking-module-id'

    //Location Link Service
    static locationLinkCreate = '/api/locations/create-with-linking';
    static locationLinkUpdate = '/api/locations/update-with-linking';

    // Pricing model services
    static pricingModelGetAll = '/api/booking-module-pricing-model/get-all-active-pricing-model';

    // Pricing Type
    static pricingTypeGetAll = '/api/pricing-type/get-all';

    // Region
    static regionGetAll = '/api/region/get-all';


    //role group services
    static roleGroupGetAll: string = '/api/role/get_all_role_groups';
    static roleGetAll: string = '/api/role/get-all';
    static userRoleGetAll: string = '/api/role/get_all_user_roles';
    static userRoleCreate: string = '/api/role/create_user_role';
    static userRoleDelete: string = '/api/role/delete_user_role';

    // Supplier Services
    static supplierGetAll: string = '/api/supplier/get-all'
    static supplierGetAllSuppliers: string = '/api/supplier/get-all-suppliers';
    static supplierCreate: string = '/api/supplier/create-supplier';
    static supplierUpdate: string = '/api/supplier/update-supplier';
    static supplierDayCount: string = '/api/supplier/get-all-supplier-day-count';
    static supplierDayCountInsert: string = '/api/supplier/create-supplier-day-count';
    static supplierDayCountUpdate: string = '/api/supplier/update-supplier-day-count';

    // Supplier Access Service
    static supplierAccessTypeGetAll = '/api/supplier/get-all-suppliers-access-type';

    //Supplier Type Services
    static supplierTypeGetAll: string = '/api/supplier/get-all-supplier-types';
    static supplierSetupGet: string = '/api/supplier/get-all-supplier-setup-for-supplier';
    static supplierSetupCreate: string = '/api/supplier/create-supplier-setup';
    static supplierSetupUpdate: string = '/api/supplier/create-supplier-setup';


    //supplier hub location link service
    static supplierLocationLinkGet: string = '/api/locations/get-all-supplier-location-hub-link';
    static supplierLocationLinkCreate: string = '/api/locations/insert-supplier-location-hub-link';

    //tarifcardmaster
    static createTarifCardMaster = '/api/tariff-card/create_tariff-card';
    static updateTarifCardMaster = '/api/tariff-card/update-tariff-card-master';
    static updateTarifCardMasterStatus = '/api/tariff-card/update-tariff-card';
    static createTarifCardRate = '/api/tariff-card/create_tariff-card-item';
    static tariffCardGetAll = '/api/tariff-card/get-all';
    static tariffCardGet = '/api/tariff-card/get-all-by-id';
    static tariffCardItemGetAll = '/api/tariff-card/get-all-tariff-card-item-ids-by-tariff-card-id';
    static tariffCardItemGetById = '/api/tariff-card/get-all-tariff-card-items-by-id';

    //Condtions
    static exclusionGetAllByCardId = '/api/exclusions/get-all-by-id';
    static exclusionGetAllByCardItemId = '/api/exclusions/get-all-by-master-and-item-id';
    static exclusionCreate = '/api/exclusions/create';
    static exclusionDelete = '/api/exclusions/delete';

    static inclusionGetAllByCardId = '/api/inclusions/get-all-by-id';
    static inclusionGetAllByCardItemId = '/api/inclusions/get-all-by-master-and-item-id';
    static inclusionCreate = '/api/inclusions/create';
    static inclusionDelete = '/api/inclusions/delete';

    static termsNConditionGetAllByCardId = '/api/terms-and-conditions/get-all-by-id';
    static termsNConditionGetAllByCardItemId = '/api/terms-and-conditions/get-all-by-master-and-item-id';
    static termsNConditionCreate = '/api/terms-and-conditions/create';
    static termsNConditionDelete = '/api/terms-and-conditions/delete';

    //service Type
    // static serviceTypeGetAll = '/api/service-type/get-all';
    static serviceTypeGetAll = '/api/booking-module/get-all';

    //cancellation Policy
    static cancelationPolicyCreate = '/api/cancellation-policy/create';
    static cancelPolicyGetAll = '/api/cancellation-policy/get-all';
    static cancelPolicyGetAllByCardId = '/api/cancellation-policy/get-all-by-id';
    static cancelPolicyGetAllByCardItemId = '/api/cancellation-policy/get-all-by-master-and-item-id';
    static cancelPolicyDelete = '/api/cancellation-policy/delete';

    //vehicle category services
    static vehicleCategoryGetAll = '/api/self-drive-vehicle-options/get-all';

    static vehicleCategory = '/api/vehicle-category/get-all'

    //vehicle fuel services
    static vehicleFuelGetAll = '/api/vehicle/get-all-vehicle-fuel';


    //vehicle engine services
    static vehicleEngineGetAll = '/api/vehicle/get-all-vehicle-engine';

    //vehicle location master
    static vehicleLocationMasterGetAll = '/api/vehicle/get-all-vehicle-location-master';
    static vehicleLocationMasterCreate = '/api/vehicle/insert-vehicle-location-master';
    static vehicleLocationMasterUpdate = '/api/vehicle/update-vehicle-location-master';

    // vehicle master
    static vehicleMasterCreate = '/api/vehicle/insert-vehicle-master';
    static vehicleMasterUpdate = '/api/vehicle/update-vehicle-manufacturer';
    static vehicleMasterGetAll = '/api/vehicle/get-all-vehicle-master';


    // vehicle manufacture
    static vehicleManufactureCreate = '/api/vehicle/insert-vehicle-manufacturer';
    static vehicleManufactureUpdate = '/api/vehicle/update-vehicle-manufacturer';
    static vehicleManufactureGetAll = '/api/vehicle/get-all-manufacturer';

    // vehicle made brand
    static vehicleMadeBrandCreate = '/api/vehicle/insert-vehicle-made-brand';
    static vehicleMadeBrandUpdate = '/api/vehicle/update-vehicle-made-brand';
    static vehicleMadeBrandGetAll = '/api/vehicle/get-vehicle-made-brands';
    static vehicleMadeBrandImages = '/api/vehicle/get-vehicle-made-brand-images-by-made-brand-id';
    static vehicleMadeBrandImageCreate = '/api/vehicle/insert-vehicle-made-brand-images';
    static vehicleMadeBrandImageUpdate = '/api/vehicle/update-vehicle-made-brand-image-status';
    static vehicleMadeBrandImageDelete = '/api/vehicle/delete-vehicle-made-brand-images';

    // vehicle transmission
    static vehicleTransmissionGetAll = '/api/vehicle/get-all-vehicle-transmisson';

    // vehicle types services
    static vehicleTyeGetAll = '/api/vehicle-type/get-all';
    static vehicleModelGetAll = '/api/vehicle-model/get-all'

    // user services
    static userServiceGetAll: string = '/api/user/get-all';
    static userServiceGetAllByAgenyId: string = '/api/user/get-all-by-agency-id';
    static userServiceCreate: string = '/api/user/create';
    static userServiceUpdate: string = '/api/user/update';

    //UserTypes services
    static userTypeServiceGetAll: string = '/api/user/get-all-user-category';
    static userTypeServiceCreate: string = '/api/user/create-user-category';
    static userTypeServiceUpdate: string = '/api/user/update-user-category';


    //calculation
    static calculationServiceGetAll: string = '/api/calculation-types/get-all';

    //PeriodType
    static periodTypeGetAll = '/api/period-types/get-all';





}

export const ServiceRoutes = {

    addOnMaster: {
        //Delete Addon Master by id
        delete:{
            url: '/api/add-on-services/delete_addon-master?',
            params: {
                id: 'AddOnId='
            }
        }
    },

    addOnService: {
        //get vehicle master by type
        getVehicleMasterByTypes: {
            url: '/api/vehicle/get-all-vehicle-master-by-types?',
            params: {
                type :'types='

            }
        }
    },


    pickAndPark: {
        //Card Master
        createCardMaster: '/api/pickandpark/create-pick-and-park-tariff-master',
        updateCardMaster: '/api/pickandpark/update-pick-and-park-tariff-master',
        getTariffMasterById: {
            url: '/api/pickandpark/get-pick-and-park-tariff-master-by-id?',
            params: {
                id: 'id='
            }
        },
        getAllTariffCardMasters: {
            url: '/api/pickandpark/get-all-pick-and-park-tariff-master'
        },
        updateCardMasterStatus: {
            url: '/api/pickandpark/update-pick-and-park-tariff-card-status'
        },
        //Card Item
        getAllTariffCardItemByCardId: {
            url: '/api/pickandpark/get-pick-and-park-tariff-card-item-by-master-id?',
            params: {
                id: 'id='
            }
        },
        getCardItemByItemId: {
            url: '/api/pickandpark/get-pick-and-park-tariff-card-item-by-id?',
            params: {
                id: 'id='
            }
        },
        createCardItem: {
            url: '/api/pickandpark/create-pick-and-park-tariff-card-item'
        },
        updateCardItem: {
            url: '/api/pickandpark/update-pick-and-park-tariff-card-item'
        },
        //Exclusions
        getAllExclusions: {
            url: '/api/pickandpark/get-all-pick-and-park-exclusions-by-type-id?',
            params: {
                type: 'Type=',
                Id: '&Id='
            }
        },
        createExclusion: {
            url: '/api/pickandpark/create-pick-and-park-terms-and-exclusions'
        },
        deleteExclusions: {
            url: '/api/pickandpark/delete-pick-and-park-terms-and-exclusions?',
            params: {
                id: 'id='
            }
        },
        // Inclusions
        getAllInclusions: {
            url: '/api/pickandpark/get-all-pick-and-park-inclusions-by-type-id?',
            params: {
                type: 'Type=',
                id: '&id='
            }
        },
        createInclusion: {
            url: '/api/pickandpark/create-pick-and-park-terms-and-inclusions'
        },
        deleteInclusion: {
            url: '/api/pickandpark/delete-pick-and-park-terms-and-inclusions?',
            params: {
                id: 'id='
            }
        },
        //Terms and conditions
        getAllConditions: {
            url: '/api/pickandpark/get-all-pick-and-park-terms-and-conditions-by-type-id?',
            params: {
                type: 'Type=',
                id: '&id='
            }
        },
        createCondition: {
            url: '/api/pickandpark/create-pick-and-park-terms-and-conditions'
        },
        deleteCondition: {
            url: '/api/pickandpark/delete-pick-and-park-terms-and-conditions?',
            params: {
                id: 'id='
            }
        },
        // Policy

        getAllPolicicies: {
            url: '/api/pickandpark/get-all-pick-and-park-cancellation-policy-by-type-id?',
            params: {
                type: 'Type=',
                id: '&id='
            }
        },
        createPolicy: {
            url: '/api/pickandpark/create-pick-and-park-cancellation-policy'
        },

        // Loation Linking
        getAllLinkedLocations: {
            url: '/api/pickandpark/get-all-pick-and-park-location-linking-service'
        },
        createLinkedLocation: {
            url: '/api/pickandpark/create-pick-and-drop-location-linking-service'
        },
        updateLinkedLocation: {
            url: '/api/pickandpark/update-pick-and-drop-location-linking-service'
        },

        //Pricing Rules
        getAllRules: {
            url: '/api/pick-and-park-pricing-rule/get-all'
        },

        createPricingRule: {
            url: '/api/pick-and-park-pricing-rule/create'
        },
        updatePricingRule: {
            url: '/api/pick-and-park-pricing-rule/get',
            params: {
                ruleId: '?ruleId=',
                status: '&status='
            }
        },
        getPricingRule: {
            url: '/api/pick-and-park-pricing-rule/get',
            params: {
                ruleId: '?ruleId='
            }
        },
        updateRulePriority: {
            url: '/api/pick-and-park-pricing-rule/update-priority'
        },

        //addon service by booking module Id
         addonServiceByBookingModule: {
             url: '/api/add-on-services/get-all-addon-service-by-booking-module-id?',
             params: {
                 id: 'id='
             }
         }

    },

    pickAndDrop: {
        //Card Master
        createCardMaster: '/api/pick-and-drop/create-pick-and-drop-tariff-master',
        updateCardMaster: '/api/pick-and-drop/update-pick-and-drop-tariff-master',
        getTariffMasterById: {
            url: '/api/pick-and-drop/get-pick-and-park-tariff-master-by-id?',
            params: {
                id: 'id='
            }
        },
        getAllTariffCardMasters: {
            url: '/api/pick-and-drop/get-all-pick-and-drop-tariff-master'
        },
        updateCardMasterStatus: {
            url: '/api/pick-and-drop/update-pick-and-drop-tariff-master-status'
        },
        //Card Item
        getAllTariffCardItemByCardId: {
            url: '/api/pick-and-drop/get-pick-and-drop-tariff-card-item-by-master-id?',
            params: {
                id: 'id='
            }
        },
        getCardItemByItemId: {
            url: '/api/pick-and-drop/get-pick-and-drop-tariff-card-item-by-id?',
            params: {
                id: 'id='
            }
        },
        createCardItem: {
            url: '/api/pick-and-drop/create-pick-and-drop-tariff-card-item'
        },
        updateCardItem: {
            url: '/api/pick-and-drop/update-pick-and-park-tariff-card-item'
        },
        //Exclusions
        getAllExclusions: {
            url: '/api/pick-and-drop/get-all-pick-and-drop-exclusions-by-type-id?',
            params: {
                type: 'Type=',
                Id: '&Id='
            }
        },
        createExclusion: {
            url: '/api/pick-and-drop/create-pick-and-drop-exclusions'
        },
        deleteExclusion: {
            url: '/api/pick-and-drop/delete-pick-and-drop-exclusions?',
            params: {
                id: 'id='
            }
        },
        // Inclusions
        getAllInclusions: {
            url: '/api/pick-and-drop/get-all-pick-and-drop-inclusions-by-type-id?',
            params: {
                type: 'Type=',
                id: '&id='
            }
        },
        createInclusion: {
            url: '/api/pick-and-drop/create-pick-and-Drop-inclusions'
        },
        deleteInclusion: {
            url: '/api/pick-and-drop/delete-pick-and-drop-inclusions?',
            params: {
                id: 'id='
            }
        },
        //Terms and conditions
        getAllConditions: {
            url: '/api/pick-and-drop/get-all-pick-and-drop-terms-and-conditions-by-type-id?',
            params: {
                type: 'Type=',
                id: '&id='
            }
        },
        createCondition: {
            url: '/api/pick-and-drop/create-pick-and-drop-terms-and-conditions'
        },
        deleteCondition: {
            url: '/api/pick-and-drop/delete-pick-and-drop-terms-and-conditions?',
            params: {
                id: 'id='
            }
        },
        // Policy

        getAllPolicicies: {
            url: '/api/pick-and-drop/get-all-pick-and-drop-cancellation-policy-by-type-id?',
            params: {
                type: 'Type=',
                id: '&id='
            }
        },
        createPolicy: {
            url: '/api/pick-and-drop/create-pick-and-drop-cancellation-policy'
        },
        deletePolicy: {
            url: '/api/pick-and-drop/delete-pick-and-drop-cancellation-policy?',
            params: {
                id: 'id='
            }
        },
        // Loation Linking
        getAllLinkedLocations: {
            url: '/api/pick-and-drop/get-all-pick-and-drop-location-linking-service'
        },
        createLinkedLocation: {
            url: '/api/pick-and-drop/create-pick-and-drop-location-linking-service'
        },
        updateLinkedLocation: {
            url: '/api/pick-and-drop/update-pick-and-drop-location-linking-service'
        },
        // Pricing Rule
        getAllRules: {
            url: '/api/pick-and-drop-pricing-rule/get-all'
        },
        updateRulePriority: {
            url: '/api/pick-and-drop-pricing-rule/update-priority'
        },
        getPricingRule: {
            url: '/api/pick-and-drop-pricing-rule/get',
            params: {
                ruleId: '?ruleId='
            }
        },
        createPricingRule: {
            url: '/api/pick-and-drop-pricing-rule/create'
        },
        updatePricingRule: {
            url: '/api/pick-and-drop-pricing-rule/get',
            params: {
                ruleId: '?ruleId=',
                status: '&status='
            }
        },

    },

    selfDrive: {
        //Card Master
        createCardMaster: '/api/self-drive-tariff-cardmaster/create',
        updateCardMaster: '/api/self-drive-tariff-cardmaster/update',
        updateCardStatus: '/api/self-drive-tariff-cardmaster/update-tariff-card-status',
        publishStatus: '/api/self-drive-tariff-cardmaster/publish-tariff-card',

        getAllTariffCardMasters: {
            url: '/api/self-drive-tariff-cardmaster/get-all'
        },
        getMasterCardById: {
            url: '/api/self-drive-tariff-cardmaster/get-self-drive-tariff-card-master-by-id?',
            params: {
                id: 'id='
            }
        },

        //Image Upload

        getVehicleImage: {
            url: '/api/self-drive-vehicle-option-images/self-drive-vehicle-option-images-by-id?',
            params: {
                id: 'id='
            }
        },

        uploadVehicleImage: {
            url:'/api/self-drive-vehicle-option-images/insert-self-drive-vehicle-option-images'
        },

        deleteVehicleImage: {
            url: '/api/self-drive-vehicle-option-images/delete-self-drive-vehicle-option-images?',
            params: {
                id: 'id='
            }
        },

        // Policy

        getAllPolicicies: {
            url: '/api/self-drive-cancellationpolicy/get-all',
        },
        createPolicy: {
            url: '/api/self-drive-cancellationpolicy/create'
        },
        deletePolicy: {
            url: '/api/self-drive-cancellationpolicy/policy?',
            params: {
                id: 'id='
            }
        },

        //Exclusions
        getAllExclusions: {
            url: '/api/self-drive-exclusions/get-all',
        },
        deleteExclusion: {
            url: '/api/self-drive-exclusions/delete?',
            params: {
                id: 'id='
            }
        },
        createExclusion: {
            url: '/api/self-drive-exclusions/create'
        },


        // Inclusions
        getAllInclusions: {
            url: '/api/self-drive-inclusion/get-all',
        },
        deleteInclusion: {
            url: '/api/self-drive-inclusion/delete?',
            params: {
                id: 'id='
            }
        },
        createInclusion: {
            url: '/api/self-drive-inclusion/create'
        },
        //Terms and conditions
        getAllConditions: {
            url: '/api/self-drive-termsandconditions/get-all',
        },
        deleteCondition: {
            url: '/api/self-drive-termsandconditions/delete?',
            params: {
                id: 'id='
            }
        },
        createCondition: {
            url: '/api/self-drive-termsandconditions/create'
        },

        //Vehicle Options
        getAllVehicleOptions: {
            url: '/api/self-drive-vehicle-options/get-all'
        },
        createVehicleOptions: {
            url: '/api/self-drive-vehicle-options/create'
        },
        updateVehicleOptions: {
            url: '/api/self-drive-vehicle-options/update'
        },
        //Pricing Rules
        getAllRules: {
            url: '/api/self-drive-pricing-rule/get-all'
        },

        updateRulePriority: {
            url: '/api/self-drive-pricing-rule/update-priority'
        },
        createPricingRule: {
            url: '/api/self-drive-pricing-rule/create'
        },
        updatePricingRule: {
            url: '/api/self-drive-pricing-rule/get',
            params: {
                ruleId: '?ruleId=',
                status: '&status='
            }
        },
        getPricingRule: {
            url: '/api/self-drive-pricing-rule/get',
            params: {
                ruleId: '?ruleId='
            }
        },
    },

    //Badge
    badge: {
        getAll: '/api/badge/get-all'
    },

    //Equipments
    equipments: {

        equipmentsGetAll: {
            url: '/api/equipments/get-all'
        },

        equipmentGroupGetAll: {
            url: '/api/equipments/get-all-equipments-group'
        },

        //equipment category
        equipmentCategory: {
            url: '/api/equipment-categories/get-all'
        },

        //create equipment
        createEquipment: {
            url: '/api/equipments/create',
        },

        //update equipment
        updateEquipmentMaster: {
            url: '/api/equipments/update',
        },

        //get all equipment master
        getAllEquipmentMaster: {
            url: '/api/equipments/get-all'
        },

        //vehicle category
        vehicleCategory: {
            url: '/api/self-drive-vehicle-category/get-all'
        },

        //create card master
        equipmentTariffCardreate: {
            url: '/api/equipments-tariff-card/create'
        },

        //get card by Id
        getMasterCardById: {
            url: '/api/equipments-tariff-card/get-equipments-tariff-card-by-id?',
            params: {
                id: 'id='
            }
        },

        //update card master
        updateCardMaster: '/api/equipments-tariff-card/update',

        //get all tariff card masters
        getAllTariffCardMasters: {
            url: '/api/equipments-tariff-card/get-all'
        },

        getImages: {
            url: '/api/equipment-image-gallery/get-by-id?'
        },

        uploadImage: {
            url: '/api/equipment-image-gallery/insert'
        },

        deleteImage: {
            url: '/api/equipment-image-gallery/delete?'
        },

        updateImageStatus: {
            url: '/api/equipment-image-gallery/update?'
        },

        //Exclusions
        getAllExclusions: {
            url: '/api/gear-rental-exclusions/get-all',
        },
        deleteExclusion: {
            url: '/api/gear-rental-exclusions/update',
        },
        createExclusion: {
            url: '/api/gear-rental-exclusions/get-all'
        },


        // Inclusions
        getAllInclusions: {
            url: '/api/gear-rental-inclusions/get-all',
        },
        deleteInclusion: {
            url: '/api/gear-rental-inclusions/update',
        },
        createInclusion: {
            url: '/api/gear-rental-inclusions/create'
        },
        //Terms and conditions
        getAllConditions: {
            url: '/api/gear-rental-terms-and-conditions/get-all',
        },
        updateCondition: {
            url: '/api/gear-rental-terms-and-conditions/update',
        },
        createCondition: {
            url: '/api/gear-rental-terms-and-conditions/create'
        },


        // piricing card status update
        publish: {
            url: '/api/equipments-tariff-card/publish-tariff-card'
        },

        updateStatus: {
            url: '/api/equipments-tariff-card/update-tariff-card-status'
        }


    }

}
