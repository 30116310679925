import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { AddonserviceComponent } from './views/addonservice/addonservice.component';
import { CarmasterComponent } from './views/carmaster/carmaster.component';
import { LocationComponent } from './views/carmaster/location/location.component';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { LocationmasterComponent } from './views/locationmaster/locationmaster.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { RentaltarifcardComponent } from './views/rentaltarifcard/rentaltarifcard.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },

  // Error Pages
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },

  // Application
  /*  {path:'carmaster',component: CarmasterComponent},    */
  /* {path: 'addonservice',component: AddonserviceComponent},   */
  /*  {path: 'locationmaster', component:LocationmasterComponent},    */
  /*  {path: 'mybookings',component:MybookingsComponent},   */
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },

  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password'
    }
  },



  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [

      {
        path: 'company',
        loadChildren: () => import('./views/company/company.module').then(x => x.CompanyModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'supplier',
        loadChildren: () => import('./views/supplier/supplier.module').then(m => m.SupplierModule)
      },
      {
        path: 'car-master',
        loadChildren: () => import('./views/carmaster/carmaster.module').then(y => y.CarmasterModule)
      },
      {
        path: 'location-master',
        loadChildren: () => import('./views/locationmaster/locationmaster.module').then(z => z.LocationmasterModule)

      },
      {
        path: 'add-on-service',
        loadChildren: () => import('./views/addonservice/addonservice.module').then(a => a.AddonServiceModule)

      },
      {
        path: 'rental-tariff-card',
        loadChildren: () => import('./views/rentaltarifcard/rentaltarifcard.module').then(b => b.RentaltarifcardModule)
      },
      {
        path: 'bookings',
        loadChildren: () => import('./views/bookings/bookings.module').then(b => b.BookingsModule)
      },

      {
        path: 'pick-and-park',
        loadChildren: () => import('./views/pick-and-park/pick-and-park.module').then(b => b.PickAndParkModule)
      },

      {
        path: 'pick-and-drop',
        loadChildren: () => import('./views/pick-and-drop/pick-and-drop.module').then(b => b.PickAndDropModule)
      },

      {
        path: 'self-drive',
        loadChildren: () => import('./views/self-drive/self-drive.module').then(b => b.SelfDriveModule)
      },
      {
        path: 'equipments',
        loadChildren: () => import('./views/self-drive-equipments/self-drive-equipments.module').then(b => b.SelfDriveEquipmentsModule)
      },
      {
        path: 'Reset-password',
        component: ChangePasswordComponent,
        data: {
          title: 'Change password'
        }
      },

    ]
  },

  { path: '**', component: P404Component },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
