import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },

  // Company

  {
    name: 'Company',
    url: '/company',
    icon: 'fa fa-building-o',
    children: [
      {
        name: 'Profile',
        url: '/company/profile',
        icon: 'fa fa-user-circle'
      },
      {
        name: 'Admin Users',
        url: '/company/user/all',
        icon: 'fa fa-users'
      },
      {
        name: 'Normal Users',
        url: '/company/user/normal-users',
        icon: 'fa fa-users'
      },
      // {
      //   name: 'Pricing Model',
      //   url: '/company/pricing-model',
      //   icon: 'icon-credit-card'
      // },
      {
        name: 'Service Model',
        url: '/company/service-model',
        icon: 'icon-credit-card'
      },
      {
        name: 'User Types',
        url: '/company/user-types',
        icon: 'fa fa-user-plus'
      },
      {
        name: 'Access Modules',
        url: '/company/access-modules',
        icon: 'icon-credit-card'
      },
    ]
  },

  {
    name: 'Supplier',
    url: '/supplier',
    icon: 'icon-layers',
    children: [
      {
        name: 'Suppliers',
        url: '/supplier/all',
        icon: 'fa fa-handshake-o'
      }
    ]
  },
  {
    name: 'Car Master',
    url: '/car-master',
    icon: 'fa fa-car',
    children: [
      {
        name: 'Manufactures',
        url: '/car-master/manufactures',
        icon: '	fa fa-cogs'
      },
      {
        name: 'Made Brand',
        url: '/car-master/brands',
        icon: 'fa fa-wrench'
      },
      // {
      //   name: 'Category',
      //   url: '/car-master/categories',
      //   icon: 'fa fa-building'
      // },
      {
        name: 'Car Master',
        url: '/car-master/cars',
        icon: 'fa fa-dashboard'
      },
      {
        name: 'Car Master Location',
        url: '/car-master/locations',
        icon: 'fa fa-cab'
      }
    ]
  },
  {
    name: 'Location  Master',
    url: '/location-master',
    icon: 'fa fa-compass',
    children: [
      {
        name: 'Locations',
        url: '/location-master/locations',
        icon: 'fa fa-location-arrow'
      },
      {
        name: 'Delivery Threshold',
        url: '/location-master/delivery-threshold',
        icon: '	fa fa-ship'
      },
      {
        name: 'Supplier Location',
        url: '/location-master/supplier-location',
        icon: 'fa fa-plane'
      }




    ]
  },
  {
    name: 'Add On Service',
    url: '/addonservice',
    icon: 'fa fa-plus-circle',
    children: [
      {
        name: 'Add On Master',
        url: '/add-on-service/masters',
        icon: 'fa fa-user-plus'
      },
      {
        name: 'Add On Service',
        url: '/add-on-service/services',
        icon: 'fa fa-building'
      },




    ]
  },
  {
    name: 'Rental Tarif Card',
    url: '/rental-tariff-card',
    icon: 'fa fa-address-card',
    children: [
      {
        name: 'Tariff Cards',
        url: '/rental-tariff-card/tariff-card/all',
        icon: 'fa fa-building',
      },
      {
        name: 'Supplier Day Count',
        url: '/rental-tariff-card/supplier-day-count',
        icon: 'fa fa-building'
      },
      {
        name: 'Cancellation Policy',
        url: '/rental-tariff-card/cancellation-policy',
        icon: 'fa fa-building'
      },
    ]
  },

  {
    name: 'Bookings',
    url: '/bookings',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Car Rental bookings',
          url: '/bookings/all',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Action Pending',
          url: '/bookings/action-pending',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Pick Park bookings',
          url: '/bookings/pick-park-bookings',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Pick Drop bookings',
          url: '/bookings/pick-drop-bookings',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'SelfDrive bookings',
          url: '/bookings/selfdrive-bookings',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Gear Rental bookings',
          url: '/bookings/gear-rental-bookings',
          icon: 'fa fa-address-book-o',
        },
      ]
  },

  {
    name: 'Pick and Park',
    url: '/pick-and-park',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Pricing Cards',
          url: '/pick-and-park/pricing-cards',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Location Linking',
          url: '/pick-and-park/location-linking',
          icon: 'fa fa-address-book-o',
        }

      ]
  },

  {
    name: 'Pick and Drop',
    url: '/pick-and-drop',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Pricing Cards',
          url: '/pick-and-drop/pricing-cards',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Location Linking',
          url: '/pick-and-drop/location-linking',
          icon: 'fa fa-address-book-o',
        }

      ]
  },

  {
    name: 'Self Drive',
    url: '/self-drive',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Vehicle Options',
          url: '/self-drive/vehicle-options',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Pricing Cards',
          url: '/self-drive/pricing-cards',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Delivery Threshold',
          url: '/self-drive/delivey-threshold',
          icon: '	fa fa-ship'
        },
      ]
  },

  {
    name: 'Equipments',
    url: '/equipments',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Equipments Master',
          url: '/equipments/equipments-master',
          icon: 'fa fa-address-book-o'
        },
        {
          name: 'Pricing Cards',
          url: '/equipments/pricing-cards',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Delivery Threshold',
          url: '/equipments/delivey-threshold',
          icon: '	fa fa-ship'
        },
      ]
  }

];


export const acaciaAdmin: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
  },
  {
    name: 'Car Master',
    url: '/car-master',
    icon: 'fa fa-car',
    children: [
      {
        name: 'Manufactures',
        url: '/car-master/manufactures',
        icon: '	fa fa-cogs'
      },
      {
        name: 'Made Brand',
        url: '/car-master/brands',
        icon: 'fa fa-wrench'
      },
      {
        name: 'Car Master',
        url: '/car-master/cars',
        icon: 'fa fa-dashboard'
      },
      {
        name: 'Car Master Location',
        url: '/car-master/locations',
        icon: 'fa fa-cab'
      }
    ]
  },
  {
    name: 'Location  Master',
    url: '/location-master',
    icon: 'fa fa-compass',
    children: [
      {
        name: 'Locations',
        url: '/location-master/locations',
        icon: 'fa fa-location-arrow'
      },
      {
        name: 'Delivery Threshold',
        url: '/location-master/delivery-threshold',
        icon: '	fa fa-ship'
      },
      {
        name: 'Supplier Location',
        url: '/location-master/supplier-location',
        icon: 'fa fa-plane'
      }
    ]
  },
  {
    name: 'Add On Service',
    url: '/addonservice',
    icon: 'fa fa-plus-circle',
    children: [
      {
        name: 'Add On Master',
        url: '/add-on-service/masters',
        icon: 'fa fa-user-plus'
      },
      {
        name: 'Add On Service',
        url: '/add-on-service/services',
        icon: 'fa fa-building'
      },
    ]
  },
  {
    name: 'Rental Tarif Card',
    url: '/rental-tariff-card',
    icon: 'fa fa-address-card',
    children: [
      {
        name: 'Tariff Cards',
        url: '/rental-tariff-card/tariff-card/all',
        icon: 'fa fa-building',
      }
    ]
  },
  {
    name: 'Bookings',
    url: '/bookings',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Car Rental bookings',
          url: '/bookings/all',
          icon: 'fa fa-address-book-o',
        }
      ]
  },

];

export const acaciaUser: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
  },
  {
    name: 'Bookings',
    url: '/bookings',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Car Rental bookings',
          url: '/bookings/all',
          icon: 'fa fa-address-book-o',
        }
      ]
  },
]


export const carTransferUser: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
  },
  {
    name: 'Pick and Park',
    url: '/pick-and-park',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Pricing Cards',
          url: '/pick-and-park/pricing-cards',
          icon: 'fa fa-address-book-o',
        },
        // {
        //   name: 'Location Linking',
        //   url: '/pick-and-park/location-linking',
        //   icon: 'fa fa-address-book-o',
        // }

      ]
  },
  {
    name: 'Pick and Drop',
    url: '/pick-and-drop',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Pricing Cards',
          url: '/pick-and-drop/pricing-cards',
          icon: 'fa fa-address-book-o',
        },
        // {
        //   name: 'Location Linking',
        //   url: '/pick-and-drop/location-linking',
        //   icon: 'fa fa-address-book-o',
        // }

      ]
  },
  {
    name: 'Bookings',
    url: '/bookings',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Pick Park bookings',
          url: '/bookings/pick-park-bookings',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Pick Drop bookings',
          url: '/bookings/pick-drop-bookings',
          icon: 'fa fa-address-book-o',
        },
      ]
  },
]

export const carDeliveryUser: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
  },
  {
    name: 'Pick and Drop',
    url: '/pick-and-drop',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Pricing Cards',
          url: '/pick-and-drop/pricing-cards',
          icon: 'fa fa-address-book-o',
        }
      ]
  },
  {
    name: 'Pick and Park',
    url: '/pick-and-park',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Pricing Cards',
          url: '/pick-and-park/pricing-cards',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Location Linking',
          url: '/pick-and-park/location-linking',
          icon: 'fa fa-address-book-o',
        }

      ]
  },
  {
    name: 'Bookings',
    url: '/bookings',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Pick Park bookings',
          url: '/bookings/pick-park-bookings',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Pick Drop bookings',
          url: '/bookings/pick-drop-bookings',
          icon: 'fa fa-address-book-o',
        },
      ]
  },
]

export const carDeliveryBookingUser: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
  },
  {
    name: 'Bookings',
    url: '/bookings',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Pick Park bookings',
          url: '/bookings/pick-park-bookings',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Pick Drop bookings',
          url: '/bookings/pick-drop-bookings',
          icon: 'fa fa-address-book-o',
        },
      ]
  },
]


export const selfdriveSighttour: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
  },
  {
    name: 'Self Drive',
    url: '/self-drive',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Vehicle Options',
          url: '/self-drive/vehicle-options',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Pricing Cards',
          url: '/self-drive/pricing-cards',
          icon: 'fa fa-address-book-o',
        }

      ]
  },

  {
    name: 'Equipments',
    url: '/equipments',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Equipments Master',
          url: '/equipments/equipments-master',
          icon: 'fa fa-address-book-o'
        },
        {
          name: 'Pricing Cards',
          url: '/equipments/pricing-cards',
          icon: 'fa fa-address-book-o',
        }
      ]
  },
  {
    name: 'Bookings',
    url: '/bookings',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'SelfDrive bookings',
          url: '/bookings/selfdrive-bookings',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Gear Rental bookings',
          url: '/bookings/gear-rental-bookings',
          icon: 'fa fa-address-book-o',
        },
      ]
  }
];


export const selfdriveReservationsSighttour: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
  },
  {
    name: 'Self Drive',
    url: '/self-drive',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Vehicle Options',
          url: '/self-drive/vehicle-options',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Pricing Cards',
          url: '/self-drive/pricing-cards',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Delivery Threshold',
          url: '/self-drive/delivey-threshold',
          icon: '	fa fa-ship'
        },
      ]
  },
  {
    name: 'Equipments',
    url: '/equipments',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'Equipments Master',
          url: '/equipments/equipments-master',
          icon: 'fa fa-address-book-o'
        },
        {
          name: 'Pricing Cards',
          url: '/equipments/pricing-cards',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Delivery Threshold',
          url: '/equipments/delivey-threshold',
          icon: '	fa fa-ship'
        },
      ]
  },
  {
    name: 'Bookings',
    url: '/bookings',
    icon: 'fa fa-book',
    children:
      [
        {
          name: 'SelfDrive bookings',
          url: '/bookings/selfdrive-bookings',
          icon: 'fa fa-address-book-o',
        },
        {
          name: 'Gear Rental bookings',
          url: '/bookings/gear-rental-bookings',
          icon: 'fa fa-address-book-o',
        },
      ]
  },
]
