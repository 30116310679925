import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigateService } from '../../services/utility/navigate.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { NotificationService } from '../../services/utility/notification.service';
import { ErrorService } from '../../services/utility/error.service';
import { UtilityService } from '../../services/utility/utility.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isSubmitted: boolean = false;
  get f() { return this.loginForm.controls };
  hasProcess: boolean;
  sessionInterval: any;

  constructor(
    private _formBuilder: FormBuilder
    , private navigateService: NavigateService
    , private authenticationService: AuthenticationService
    , private notificationService: NotificationService
    , private errorService: ErrorService
    , private utilityService: UtilityService
  ) {
    this.hasProcess = false;
    this.sessionInterval = Function;
  }

  ngOnInit(): void {
    
    this.authenticationService.getAppSettings().subscribe(appSettings => {
      if (appSettings) {
        this.navigateService.goToDashboard();
      }
    });
    this.initFormGroup();
  }


  initFormGroup(): void {

    this.loginForm = this._formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      agencyCode: ['', Validators.required]
    });
  }

  onSignIn(): void {
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    let username = this.loginForm.controls['username'].value;
    let password = this.loginForm.controls['password'].value;
    let agencyCode = this.loginForm.controls.agencyCode.value;
    this.hasProcess = true;
    this.authenticationService.signIn(username, password, agencyCode).subscribe(response => {
      this.hasProcess = false;
      if (response == null) {
        this.notificationService.errorNotification('', 'Invalid username or password.')
      } else if (!response.isActive) {
        this.notificationService.errorNotification('Deactivated', 'Your account has been deactivated.');
      } else {
        this.authenticationService.saveAppsettings(response);
        this.authenticationService.saveAccessToken(response.accessToken);
        this.notificationService.successNotification('Success', 'Welcome! Account has been logged.');
        this.navigateService.goToDashboard();
        // console.log(response);
      }
    }, errorResponse => {
      this.errorService.errorHandler(errorResponse);
      this.hasProcess = false;
    });



  }

}
